@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile styles
.closer-statement-section {
  background-color: var.$color-neutral-grey-0;
  color: var.$color-neutral-white;
  text-align: center;
  min-height: 700px;
  display: flex;
  overflow: hidden;
}

.closer-statement-child-section {
  z-index: 1;
}

.outer-grid-container {
  padding-top: fn.spacing(4);
}

.grid-item-text {
  order: 1;
}

.title {
  padding-bottom: fn.spacing(3);
  line-height: 140%;
}

.button {
  margin: 0 fn.spacing(0.3);
  z-index: 1;
}

.alt-cta {
  align-self: center;
  padding-top: fn.spacing(5);
}

@media screen and (min-width: var.$breakpoint-md) {
  .closer-statement-section {
    min-height: 1000px;
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .closer-statement-section {
    min-height: 1200px;
  }
}

/* Desktop (Xlarge or greater) styles */
@media screen and (min-width: var.$breakpoint-xl) {
  .closer-statement-section {
    text-align: left;
    min-height: initial;
    height: 339px;
  }

  .outer-grid-container {
    padding-top: 0;
    max-width: 920px;
    height: 339px;
    margin: auto;
  }

  [data-right-content='true'].outer-grid-container {
    justify-content: right;
  }

  .text-container {
    padding: 0;
    max-width: 450px;
  }

  .title {
    padding-bottom: fn.spacing(4);
  }

  .cta-container {
    margin: 2px;
    display: flex;
  }

  .alt-cta {
    padding: 0 0 0 fn.spacing(5);
  }
}
